<div class="dashboard-container">
  <nav class="sidebar">
    <ul>
      <div class="sidebar__itens" *ngFor="let item of menuItems">
        <div
          class="sidebar__itens__icons"
          [ngClass]="{ 'active-link': isActive(item.route) }"
          (click)="item.hasSubmenu ? toggleSubmenu(item.route) : null"
        >
          <img [src]="'../../../../assets/imgs/svg/' + item.icon">
          <li [routerLink]="item.route">{{ item.label }}</li>
        </div>
        <mat-icon>
          {{ submenuVisible && activeRoute === item.route ? 'expand_more' : 'chevron_right' }}
        </mat-icon>

        <!-- Submenu para "Controle de Links Rodapé" -->
        <div *ngIf="item.hasSubmenu && submenuVisible" class="submenu" [ngClass]="{ 'border-top': submenuVisible }">
          <div *ngFor="let subItem of submenuItems" class="content-link-submenu">
            <img [src]="'../../../../assets/imgs/svg/' + subItem.iconSubmenu" alt="Icone do submenu">
            <a [routerLink]="subItem.route">{{ subItem.label }}</a>
          </div>
        </div>
      </div>
    </ul>
  </nav>

  <div class="content"></div>
</div>
