import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})


export class SidebarComponent {
  submenuVisible: boolean = false;
  activeRoute?: string;

  menuItems = [
    { icon: 'icon-dashboard.svg', route: 'dashboard', label: 'Dashboard' },
    { icon: 'control-user.svg', route: 'controle-usuarios', label: 'Controle de Usuários' },
    { icon: 'control-perfil.svg', route: '', label: 'Controle de Perfil' },
    { icon: 'control-param.svg', route: 'controle-parametros', label: 'Controle de Parâmetros' },
    { icon: 'control-bloqueios.svg', route: 'controle-bloqueios', label: 'Controle de Bloqueios' },
    { icon: 'control-noticias.svg', route: 'controle-noticias', label: 'Controle de Notícias' },
    { icon: 'control-galeria.svg', route: 'controle-galeria', label: 'Controle de Galeria' },
    { icon: 'faq-admin.svg', route: 'perguntas-frequentes-admin', label: 'Perguntas Frequentes' },
    { icon: 'links-rodape.svg', label: 'Controle de Links Rodapé', hasSubmenu: true }
  ];

  submenuItems = [
    { iconSubmenu: 'Right-sidebar.svg', route: 'links-canais-digitais', label: 'Canais Digitais' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'links-servicos', label: 'Serviços' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'links-telefones', label: 'Telefones Úteis' },
    { iconSubmenu: 'Right-sidebar.svg', route: 'links-redes-sociais', label: 'Redes Sociais' }
  ];

  constructor(private router: Router) {}

  isActive(route?: string): boolean {
    return route ? this.activeRoute === route : false;
  }

  toggleSubmenu(itemRoute: string | undefined): void {
    this.submenuVisible = !this.submenuVisible;
    this.activeRoute = itemRoute;
  }
}
